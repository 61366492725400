import React from 'react'
import { Grid, FormHelperText } from '@mui/material'
import * as S from './style'
import InputAdornment from '@mui/material/InputAdornment';
import { RiCalendarEventFill } from 'react-icons/ri'
import Autocomplete from '@mui/material/Autocomplete';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { getSellers } from 'utils/requests/other';
import { editProjectCommercial } from 'utils/requests/comercial';
import { createProject } from 'utils/requests/project';
import showToast from 'components/Toast/Toast';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { TokenContext } from "context/TokenContext";
import { getAllInfluencers } from 'utils/requests/auth';
import { getAllClients } from 'utils/requests/leads';

const AddProject = () => {
  moment.locale("pt-br");

  const navigate = useNavigate();

  const [influencers, setInfluencers] = React.useState();
  const [businesses, setBusinesses] = React.useState();
  const [sellers, setSellers] = React.useState();

  const projectTypes = ['Briefing', 'Campanha'/* , 'Eventos' */];

  const fetchAllUsers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setInfluencers(data);
    };
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    };
  };

  const fetchSellers = async () => {
    const data = await getSellers();
    if (!data.message) {
      setSellers(data);
    };
  };

  React.useEffect(() => {
    fetchAllUsers();
    fetchBusinesses();
    fetchSellers();
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
  };

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const redirectToProject = {
    ADMINISTRATOR: '/projetos/listagem/',
    H_COMERCIAL: '/comercial/projetos/'
  };

  const addSellersAndBusiness = async(id, generalProjectId, commercialInfo) => {
    await editProjectCommercial(id, commercialInfo).then((res) => {
      if (res?.id) {
        showToast("Projeto criado com sucesso! Redirecionando...", "success");
        setTimeout(() => {
          navigate(`${redirectToProject[role]}${generalProjectId}`);
        }, 2000)
      } else {
        showToast("Erro inesperado ao adicionar projeto ou vendedor", "warning");
      };
    });
  }

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: '',
      business: null,
      start: '',
      end: '',
      projectType: '',
      currency: 'BRL',
      influencers: [],
      description: '',
      sellers: []
    },
    validationSchema: Yup.object({
      title: Yup.string().min(4, 'Nome do projeto deve conter pelo menos 4 caracteres').required('Nome do projeto é obrigatório'),
      business: Yup.object().nullable(),
      sellers: Yup.array(),
      start: Yup.string().when('projectType', {
        is: (value) => value !== 'Briefing',
        then: () => Yup.string().required('Data Inicial é obrigatório'),
        otherwise: () => Yup.string().required('Data Inicial é obrigatório'),
      }),
      end: Yup.string(),
      projectType: Yup.string().required("Tipo de projeto é obrigatório"),
      currency: Yup.string().required("Moeda corrente é obrigatório"),
      influencers: Yup.array().min(1, "Projeto deve ter pelo menos um influencer vinculado"),
      description: Yup.string().required("Descrição é obrigatório"),
    }),
    onSubmit: async (values) => {
      const projectData = {
        name: values.title,
        startDate: values.start ? unixDate(values.start) : '',
        endDate: values.end ? unixDate(values.end) : '',
        type: values.projectType.toUpperCase(),
        currency: values.currency,
        influencers: values.influencers ? values.influencers.map((influencer) => influencer.id) : '',
        description: values.description,
      };
      const commercialData = {
        leadsRelated: values?.business?.id ?? '',
        usersMembers: values.sellers.length > 0 ? values.sellers.map((seller) => seller.id) : '',
      }
      Object.keys(projectData).forEach(key => {
        if (projectData[key] === '') {
          delete projectData[key];
        }
      });
      Object.keys(commercialData).forEach(key => {
        if (commercialData[key] === '') {
          delete commercialData[key];
        }
      });
      await createProject(projectData).then((res) => {
        if (res.projectId) {
          addSellersAndBusiness(res.projectComercialId, res.projectId, commercialData);
        } else {
          showToast("Falha na criação do projeto", "error");
        };
      });
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#start", flatpickrOptions('start', formik.setFieldValue, formik.setFieldTouched));
  flatpickr("#end", flatpickrOptions('end', formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <p>Criar Projeto</p>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' marginTop='30px' padding='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='title'
                  id='title'
                  type='text'
                  placeholder="Nome do Projeto"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={Boolean(formik.errors.title)}
                  helperText={formik.errors.title}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='business'
                  disablePortal
                  value={formik.values.business ? formik.values.business : null}
                  onChange={(e, value) => {
                    formik.setFieldTouched('business')
                    formik.setFieldValue('business', value ? value : '')
                  }}
                  options={businesses ? businesses[0] : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.businessName}
                  renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id="sellers"
                  name="sellers"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={formik.values.sellers ? formik.values.sellers : []}
                  onChange={(e, value) => {
                    formik.setFieldValue('sellers', value)
                  }}
                  options={sellers ? sellers : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Vendedores' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id='start'>
                <S.Input
                  name='start'
                  hiddenLabel
                  type='text'
                  placeholder="Data Inicial"
                  fullWidth
                  data-input
                  autoComplete='off'
                  value={formik.values.start}
                  error={Boolean(formik.errors.start)}
                  helperText={formik.errors.start}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CloseIcon onClick={() => formik.setFieldValue('start', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id='end'>
                <S.Input
                  name='end'
                  hiddenLabel
                  type='text'
                  placeholder="Data Final"
                  fullWidth
                  data-input
                  autoComplete='off'
                  value={formik.values.end}
                  error={Boolean(formik.errors.end)}
                  helperText={formik.errors.end}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCalendarEventFill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CloseIcon onClick={() => formik.setFieldValue('end', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Grid container>
                  <Autocomplete
                    id="projectType"
                    name="projectType"
                    disablePortal
                    value={formik.values.projectType ? formik.values.projectType : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('projectType', value)
                    }}
                    options={projectTypes ? projectTypes : []}
                    sx={[{ width: "100%" }, formik.errors.projectType && { border: "1px solid #f44336", borderRadius: "3px" }]}
                    renderInput={(params) => <S.Input {...params} placeholder='Tipo de Projeto' />}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                </Grid>
                {Boolean(formik.errors.projectType) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.projectType}</FormHelperText>}
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='currency'
                  id='currency'
                  type='text'
                  placeholder="Moeda Corrente"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  onChange={formik.handleChange}
                  value={formik.values.currency}
                  error={Boolean(formik.errors.currency)}
                  helperText={formik.errors.currency}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={12} xl={12}>
                <Autocomplete
                  id="influencers"
                  name="influencers"
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  value={formik.values.influencers ? formik.values.influencers : []}
                  onChange={(e, value) => {
                    formik.setFieldValue('influencers', value)
                  }}
                  options={influencers ? influencers[0] : []}
                  sx={[{ width: "100%" }, formik.errors.influencers && { border: "1px solid #f44336", borderRadius: "3px" }]}
                  renderInput={(params) => <S.Input {...params} placeholder='Influencers' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
                {Boolean(formik.errors.influencers) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.influencers}</FormHelperText>}
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={12} xl={12}>
                <S.Input
                  name='description'
                  id='description'
                  hiddenLabel
                  type='text'
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={Boolean(formik.errors.description)}
                  helperText={formik.errors.description}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <ButtonCustom label='SALVAR' sx={{ width: "180px", fontSize: "18px", fontWeight: "500" }} type='submit' />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

export default AddProject