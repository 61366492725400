import React, { useEffect } from 'react';
import Dashboard from "layouts/dashboard";
import Influencers from "pages/Influencers/Influencers";
import Finance from "pages/Finance/Finance";
import Construction from "pages/Construction/Construction";
import homeIcon from '../../src/assets/icons/home.png'
import gameIcon from '../../src/assets/icons/gamefi.png'
import walletIcon from '../../src/assets/icons/wallet.png'
import fundsIcon from '../../src/assets/icons/funds.png'
import nftIcon from '../../src/assets/icons/nft.png'
import marketingIcon from '../../src/assets/icons/marketing.png'
import tvIcon from '../../src/assets/icons/stream.png'
import calendarIcon from '../../src/assets/icons/calendar.png'
import configIcon from '../../src/assets/icons/settings.png'
import projectsIcon from '../../src/assets/icons/projects.png'
import Calendar from "pages/Calendar/Calendar";
import InfluencerFinance from 'pages/Finance/UserViews/InfluencerFinance';
import Commercial from 'pages/Commercial/Commercial';
import Kanban from 'pages/Commercial/Kanban/Kanban';
import Clients from 'pages/Commercial/Clients/Clients';
import GeneralCashout from 'pages/Finance/Cashout/GeneralCashout';
import Pipeline from 'pages/Commercial/Pipeline';
import jwt_decode from "jwt-decode";
import ProjectListCommercial from 'pages/Projects/ProjectList/ProjectListCommercial';
import ProjectListInfluencer from 'pages/Projects/ProjectList/ProjectListInfluencer';
import ProjectListMarketing from 'pages/Projects/ProjectList/ProjectListMarketing';
import Marketing from 'pages/Marketing/Marketing';
import UserList from 'pages/SettingsPage/UsersList/UsersList';

const getUsername = () => {
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwt_decode(token) : null;
  const username = decodedToken?.username;
  return username
};

export const roleBasedRoutes = {
  ADMINISTRATOR: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Dashboard,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Finanças",
      key: "financeiro",
      route: "/financeiro",
      icon: <img src={walletIcon} />,
      component: Finance,
      subRoutes: [
        {
          name: "Saque",
          key: "saque",
          route: "/financeiro/saque",
          component: GeneralCashout,
        },
      ],
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Commercial,
      subRoutes: [
        {
          name: "Negócios",
          key: "negocios",
          route: "/comercial/negocios",
          component: ProjectListMarketing,
        },
        {
          name: "Pipeline",
          key: "pipeline",
          route: "/comercial/pipeline",
          component: Pipeline,
        },
        {
          name: "Clientes",
          key: "clientes",
          route: "/comercial/clientes",
          component: Clients,
        },
      ],
    },
    {
      type: "collapse",
      name: "Marketing",
      key: "marketing",
      route: "/marketing",
      icon: <img src={marketingIcon} />,
      component: Construction,
      subRoutes: [
        {
          name: "Projetos",
          key: "projetos",
          route: "/marketing/projetos",
          component: ProjectListCommercial,
        },
        {
          name: "Pipe de Produção",
          key: "pipeline",
          route: "/marketing/pipeline",
          component: Pipeline,
        },
      ],
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
      subRoutes: [
        {
          name: "Usuários",
          key: "usuarios",
          route: "/configuracoes/usuarios",
          component: UserList,
        }
      ],
    },
  ],
  H_COMERCIAL: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Marketing,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Commercial,
      subRoutes: [
        {
          name: "Negocios",
          key: "negocios",
          route: "/comercial/negocios",
          component: ProjectListCommercial,
        },
        {
          name: "Pipeline",
          key: "pipeline",
          route: "/comercial/pipeline",
          component: Pipeline,
        },
        {
          name: "Clientes",
          key: "clientes",
          route: "/comercial/clientes",
          component: Clients,
        },
      ],
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ],
  COMERCIAL: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Marketing,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Commercial,
      subRoutes: [
        {
          name: "Negócios",
          key: "negocios",
          route: "/comercial/negocios",
          component: ProjectListCommercial,
        },
        {
          name: "Pipeline",
          key: "pipeline",
          route: `/comercial/pipeline/${getUsername()}`,
          component: Kanban,
        },
        {
          name: "Clientes",
          key: "clientes",
          route: "/comercial/clientes",
          component: Clients,
        },
      ],
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ],
  H_MARKETING: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Marketing,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Commercial,
      subRoutes: [
        {
          name: "Negócios",
          key: "negocios",
          route: "/comercial/negocios",
          component: ProjectListCommercial,
        },
        {
          name: "Clientes",
          key: "clientes",
          route: "/comercial/clientes",
          component: Clients,
        },
      ],
    },
    {
      type: "collapse",
      name: "Marketing",
      key: "marketing",
      route: "/marketing",
      icon: <img src={marketingIcon} />,
      component: Construction,
      subRoutes: [
        {
          name: "Projetos",
          key: "projetos",
          route: "/marketing/projetos",
          component: ProjectListCommercial,
        },
        {
          name: "Pipe de Produção",
          key: "pipeline",
          route: "/marketing/pipeline",
          component: Pipeline,
        },
      ],
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ],
  MARKETING: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Marketing,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Marketing",
      key: "marketing",
      route: "/marketing",
      icon: <img src={marketingIcon} />,
      component: Construction,
      subRoutes: [
        {
          name: "Projetos",
          key: "projetos",
          route: "/marketing/projetos",
          component: ProjectListCommercial,
        },
        {
          name: "Pipe de Produção",
          key: "pipeline",
          route: `/marketing/pipeline/kanban`,
          component: Pipeline,
        },
      ],
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ],
  STANDARD: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "",
      route: "/",
      icon: <img src={homeIcon} />,
      component: Dashboard,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Finanças",
      key: "financeiro",
      route: "/financeiro",
      icon: <img src={walletIcon} />,
      component: Finance,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /*     {
          type: "collapse",
          name: "Projetos",
          key: "projetos",
          route: "/projetos",
          icon: <img src={projectsIcon} />,
          component: Projects,
          noCollapse: true,
        }, */
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ],
  TESTER: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "",
      route: "/",
      icon: <img src={homeIcon} />,
      component: Dashboard,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Influencers",
      key: "influencers",
      route: "/influencers",
      icon: <img src={gameIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Finanças",
      key: "financeiro",
      route: "/financeiro",
      icon: <img src={walletIcon} />,
      component: Finance,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Comercial",
      key: "comercial",
      route: "/comercial",
      icon: <img src={fundsIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /*     {
          type: "collapse",
          name: "Projetos",
          key: "projetos",
          route: "/projetos",
          icon: <img src={projectsIcon} />,
          component: Projects,
          noCollapse: true,
        }, */
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /*   {
        type: "collapse",
        name: "Sign Up",
        key: "sign-up",
        route: "/authentication/sign-up",
        icon: <IoRocketSharp size="15px" color="inherit" />,
        component: SignUp,
        noCollapse: true,
      }, */
  ],
  INFLUENCER: () => [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <img src={homeIcon} />,
      component: Influencers,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Finanças",
      key: "financeiro",
      route: "/financeiro",
      icon: <img src={walletIcon} />,
      component: InfluencerFinance,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Projetos",
      key: "projetos",
      route: "/projetos",
      icon: <img src={projectsIcon} />,
      component: ProjectListInfluencer,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "NFTs",
      key: "nft",
      route: "/nft",
      icon: <img src={nftIcon} />,
      component: Construction,
      noCollapse: true,
    },
    /* { type: "title", title: "Account Pages", key: "account-pages" }, */
    {
      type: "collapse",
      name: "ViuzzTV",
      key: "tv",
      route: "/tv",
      icon: <img src={tvIcon} />,
      component: Construction,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Agenda",
      key: "agenda",
      route: "/agenda",
      icon: <img src={calendarIcon} />,
      component: Calendar,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Configurações",
      key: "configuracoes",
      route: "/configuracoes",
      icon: <img src={configIcon} />,
      component: Construction,
      noCollapse: true,
    },
  ]
}